import type { getCurrentStoreOutputSchema } from "~/validations/store";

export const useIsDrawerOpen = () =>
  useState<boolean>("isDrawerOpen", () => false);

export const useCurrentStore = () =>
  useState<output<typeof getCurrentStoreOutputSchema> | null>(
    "currentStore",
    () => null,
  );

export const useIsAuthed = () => useState<boolean>("isAuthed", () => false);

export const useCurrency = () => useState<string>("currency", () => "DT");
